<template>
  <div />
</template>

<script>
import { onLogout } from '../vue-apollo';
import { AUTH_TOKEN, VUEX_STORE } from '../constants/storage';

export default {
  data() {
    return {};
  },
  methods: {
    async resetData() {
      await onLogout();
      this.$store.commit('restoreSession');
      localStorage.removeItem(VUEX_STORE);
      localStorage.removeItem(AUTH_TOKEN);
    },
  },
  async mounted() {
    if (!this.isEmbedded) await this.resetData();
    /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
    if (this.isPageflowV2) {
      this.$emit('next');
    } else {
      this.$store.commit('resetRegistration');
      this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
    }
  },
};
</script>
